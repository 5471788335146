import React from "react";

function Terms() {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold text-center mb-6">
          Terms & Conditions
        </h1>
        <p className="text-gray-500 text-sm mb-6 text-center">
          Effective Date: 06-08-2024
        </p>

        <section className="text-gray-800 leading-7">
          <p className="mb-4">
            Welcome to Connecticx! These Terms and Conditions ("Terms") govern
            your use of the Connecticx website and services ("Services"). By
            accessing or using our Services, you agree to comply with and be
            bound by these Terms. If you do not agree to these Terms, please do
            not use our Services.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            1. Acceptance of Terms
          </h2>
          <p className="mb-4">
            By accessing or using Connecticx, you confirm that you have read,
            understood, and agree to be bound by these Terms and all applicable
            laws and regulations.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            2. Description of Services
          </h2>
          <p className="mb-4">
            Connecticx is an online event ticketing platform that promotes
            events and provides a platform to buy and sell tickets. Our Services
            allow users to create, discover, and participate in events.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">3. User Accounts</h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            3.1 Account Registration
          </h3>
          <p className="mb-4">
            To use certain features of our Services, you may be required to
            register for an account. You must provide accurate and complete
            information and keep your account information updated.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            3.2 Account Responsibility
          </h3>
          <p className="mb-4">
            You are responsible for maintaining the confidentiality of your
            account login information and for all activities that occur under
            your account. You agree to notify us immediately of any unauthorized
            use of your account.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            4. Event Organizers and Attendees
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            4.1 Event Organizers
          </h3>
          <p className="mb-4">
            Event organizers are responsible for ensuring that their events
            comply with all applicable laws and regulations. Connecticx is not
            responsible for the content, quality, or outcomes of events
            organized through our platform. If anything goes wrong at the event,
            Connecticx is not responsible, as we are merely a ticketing
            platform. The type and quality of the event depend solely on the
            organizer.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">4.2 Attendees</h3>
          <p className="mb-4">
            Attendees are responsible for ensuring that they meet all
            requirements for attending an event, including age restrictions,
            health and safety guidelines, and any other relevant criteria.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            5. Ticket Sales and Purchases
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            5.1 Ticket Pricing
          </h3>
          <p className="mb-4">
            Ticket prices are set by event organizers and may include service
            fees charged by Connecticx. Prices and availability of tickets are
            subject to change without notice.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            5.2 Ticket Purchase
          </h3>
          <p className="mb-4">
            When you purchase a ticket through Connecticx, you agree to pay the
            specified price for the ticket and any applicable fees. All sales
            are final, and no refunds or exchanges will be provided unless
            otherwise stated by the event organizer. If an event is canceled due
            to serious reasons, you will be notified, and a refund for the
            ticket will be issued within 7 working days. All other refund
            requests will not be entertained.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">6. User Conduct</h2>
          <p className="mb-4">You agree not to use Connecticx to:</p>
          <ul className="list-disc list-inside mb-4">
            <li>
              Violate any local, state, national, or international law or
              regulation.
            </li>
            <li>Infringe on the intellectual property rights of others.</li>
            <li>
              Transmit any material that is unlawful, harmful, threatening,
              abusive, defamatory, vulgar, obscene, or otherwise objectionable.
            </li>
            <li>
              Engage in any conduct that restricts or inhibits any other user
              from using or enjoying our Services.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            7. Intellectual Property
          </h2>
          <p className="mb-4">
            All content and materials on Connecticx, including but not limited
            to text, graphics, logos, and software, are the property of
            Connecticx or its licensors and are protected by intellectual
            property laws. You may not use, reproduce, or distribute any content
            from our Services without our express written permission.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            8. Privacy Policy
          </h2>
          <p className="mb-4">
            Your use of Connecticx is also governed by our Privacy Policy, which
            can be found [here](insert link). Please review our Privacy Policy
            to understand our practices regarding the collection, use, and
            disclosure of your personal information.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">9. Disclaimers</h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            9.1 Service Availability
          </h3>
          <p className="mb-4">
            Connecticx strives to ensure that our Services are available at all
            times. However, we do not guarantee that our Services will be
            uninterrupted, secure, or error-free.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            9.2 Event Responsibility
          </h3>
          <p className="mb-4">
            Connecticx is not responsible for any events listed on our platform,
            including but not limited to event cancellations, changes, or any
            issues that may arise during or after an event. We are merely a
            ticketing platform, and the type and quality of the event depend
            solely on the organizer. If anything goes wrong at the event,
            Connecticx is not responsible.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            10. Limitation of Liability
          </h2>
          <p className="mb-4">
            To the fullest extent permitted by law, Connecticx shall not be
            liable for any direct, indirect, incidental, special, or
            consequential damages arising out of or in connection with your use
            of our Services, even if we have been advised of the possibility of
            such damages.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            11. Indemnification
          </h2>
          <p className="mb-4">
            You agree to indemnify, defend, and hold harmless Connecticx, its
            affiliates, and their respective officers, directors, employees, and
            agents from and against any claims, liabilities, damages, losses,
            and expenses, including reasonable attorneys' fees, arising out of
            or in any way connected with your access to or use of our Services.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            12. Governing Law
          </h2>
          <p className="mb-4">
            These Terms shall be governed by and construed in accordance with
            the laws of [Your Country/State], without regard to its conflict of
            law principles.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            13. Changes to Terms
          </h2>
          <p className="mb-4">
            Connecticx reserves the right to modify or replace these Terms at
            any time. We will notify you of any changes by posting the new Terms
            on our website. Your continued use of our Services after any such
            changes constitutes your acceptance of the new Terms.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            14. Contact Information
          </h2>
          <p className="mb-4">
            If you have any questions about these Terms, please contact us at:
          </p>
          <p className="font-semibold">Email:</p>
          <p className="mb-4">connecticx7@gmail.com</p>
        </section>
      </div>
    </div>
  );
}

export default Terms;
