import React from "react";
import Profile from "../../images/profile.png";
// Sample testimonials data with direct image URLs
const testimonials = [
  {
    name: "Aarav Sharma",
    role: "Premium Member",
    content:
      "The platform has truly made my business processes seamless. The support team is excellent and always ready to assist.",
    image: Profile,
  },
  {
    name: "Ishita Kapoor",
    role: "Frequent Buyer",
    content:
      "I love how easy it is to find exactly what I need here. The service is quick, reliable, and hassle-free!",
    image: Profile,
  },
  {
    name: "Rohan Verma",
    role: "Customer",
    content:
      "I've been using this service for years now, and it just keeps getting better. Highly recommend it to everyone!",
    image: Profile,
  },
  {
    name: "Ananya Mehta",
    role: "First-Time User",
    content:
      "As a first-time user, I was impressed by the user-friendly interface and prompt assistance. Kudos to the team!",
    image: Profile,
  },
];

function TestimonialCard({ testimonial }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <p className="text-gray-600 mb-4">"{testimonial.content}"</p>
      <div className="flex items-center">
        <img
          src={testimonial.image}
          alt={testimonial.name}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h4 className="font-semibold">{testimonial.name}</h4>
          <p className="text-purple-600 text-sm">{testimonial.role}</p>
        </div>
      </div>
    </div>
  );
}

function Testimonials() {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">
          What Our Clients Say about Us
        </h2>

        <div className="flex justify-center mb-8">
          <div className="flex items-center space-x-4 bg-gray-100 p-4 rounded-lg shadow-md">
            <img
              src={Profile}
              alt="Client Profile"
              className="w-12 h-12 rounded-full"
            />
            <div>
              <h3 className="text-lg font-semibold">Our Clients reviews</h3>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
