import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { QRCodeSVG } from "qrcode.react";
import TicketComponent from "./TicketComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import qrcode from "qrcode";

const eventDetails = {
  1: {
    title: "Terrace House Party",
    date: "January 25, 2025",
    time: "8:00 PM - 12:00 AM",
    location: "Hingna, Nagpur ",
  },
  2: {
    title: "Startup Summit",
    date: "December 22, 2024",
    time: "11:00 AM - 1:00 PM",
    location: "Chhatrapati Square, Nagpur ",
  },
  3: {
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    time: "7:00 AM - 8:00 AM",
    location: "Japanese Garden, Nagpur",
  },
};

const ConfirmationPage = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailStatus, setEmailStatus] = useState(null);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const { orderId } = useParams();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!orderId) return;
      try {
        const docRef = doc(db, "orders", orderId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const ticketId = docSnap.id;
          const event = eventDetails[data.eventId] || {
            title: "Event",
            date: "TBD",
            time: "TBD",
            location: "TBD",
          };

          const enrichedData = {
            ...data,
            ticketId,
            eventTitle: event.title,
            eventDate: event.date,
            eventTime: event.time,
            eventLocation: event.location,
          };

          setOrderDetails(enrichedData);

          const qrCodeData = JSON.stringify({
            ticketId,
            eventName: event.title,
            attendeeName: `${data.firstName || ""} ${data.lastName || ""}`,
          });

          qrcode
            .toDataURL(qrCodeData)
            .then((url) => {
              setQrCodeImage(url);
            })
            .catch((err) => {
              console.error("Failed to generate QR code", err);
            });
        } else {
          setError("Order not found");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Error fetching order details");
      }
    };
    fetchOrderDetails();
  }, [orderId]);

  const generatePDF = async () => {
    setLoading(true);
    const ticketElement = document.getElementById("ticket");

    try {
      // Wait a bit for QR code to be fully rendered
      await new Promise((resolve) => setTimeout(resolve, 500));

      const canvas = await html2canvas(ticketElement, {
        scale: 2, // Higher scale for better quality
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
        // Capture the exact dimensions of the ticket
        windowWidth: ticketElement.offsetWidth,
        windowHeight: ticketElement.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Create PDF with the same aspect ratio as the ticket
      const ticketAspectRatio = canvas.width / canvas.height;
      const pdf = new jsPDF({
        orientation: ticketAspectRatio > 1 ? "landscape" : "portrait",
        unit: "mm",
        format: "a4",
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calculate dimensions to maintain aspect ratio
      let imgWidth = pageWidth - 20; // 10mm margin on each side
      let imgHeight = imgWidth / ticketAspectRatio;

      // If height exceeds page height, scale down
      if (imgHeight > pageHeight - 20) {
        imgHeight = pageHeight - 20;
        imgWidth = imgHeight * ticketAspectRatio;
      }

      // Center the image on the page
      const x = (pageWidth - imgWidth) / 2;
      const y = (pageHeight - imgHeight) / 2;

      pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);

      return {
        pdfBase64: pdf.output("datauristring").split(",")[1],
        pdf: pdf,
      };
    } catch (error) {
      console.error("Error generating PDF:", error);
      throw new Error("Failed to generate PDF");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      setLoading(true);
      const { pdf } = await generatePDF();
      pdf.save(`ticket-${orderId}.pdf`);
      setError(null);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setError("Failed to download PDF");
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      setLoading(true);
      setEmailStatus("sending");
      setError(null);

      const { pdfBase64 } = await generatePDF();

      const response = await fetch(
        "https://connecticx-server.vercel.app/api/send-ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: orderDetails.email,
            pdfTicket: pdfBase64,
            orderId: orderId,
            eventDetails: {
              name: orderDetails.eventTitle,
              date: orderDetails.eventDate,
              time: orderDetails.eventTime,
              location: orderDetails.eventLocation,
            },
          }),
        }
      );

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const result = await response.json();

      if (result.success) {
        setEmailStatus("success");
        setError(null);
      } else {
        throw new Error(result.error || "Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setEmailStatus("error");
      setError(`Failed to send email: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!orderDetails) return <div>Loading order details...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4 text-purple-600">
        Order Confirmation
      </h1>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div id="ticket">
        <TicketComponent orderDetails={orderDetails} />
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-4">
        <div className="flex flex-col gap-4">
          <button
            onClick={handleDownloadPDF}
            disabled={loading}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-200 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:hover:scale-100 shadow-md"
          >
            {loading ? "Processing..." : "Download Ticket as PDF"}
          </button>

          <button
            onClick={handleSendEmail}
            disabled={loading || emailStatus === "sending"}
            className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-lg transition duration-200 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:hover:scale-100 shadow-md"
          >
            {emailStatus === "sending"
              ? "Sending Email..."
              : "Send Ticket to Email"}
          </button>

          <p className="text-gray-600 text-sm mt-2 text-center italic">
            Please save this ticket in your email for quick and easy check-in at
            the event.
          </p>

          {emailStatus && (
            <div
              className={`mt-2 p-2 rounded ${
                emailStatus === "success"
                  ? "bg-green-100 text-green-700"
                  : emailStatus === "error"
                  ? "bg-red-100 text-red-700"
                  : "bg-yellow-100 text-yellow-700"
              }`}
            >
              {emailStatus === "success" &&
                `Ticket sent successfully to ${orderDetails.email}!`}
              {emailStatus === "error" &&
                `Failed to send ticket. Please try again.`}
              {emailStatus === "sending" &&
                `Sending ticket to ${orderDetails.email}...`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
