import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import img40 from "../../images/yoga.jpg";
import img20 from "../../images/20.png";
import img19 from "../../images/19.png";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

const eventDetails = {
  1: {
    id: 1,
    title: "Terrace House Party",
    date: "January 25, 2025",
    image: img20,
    time: "8:00 PM - 12:00 AM",
    description:
      "  Get ready to kick off your weekend in style! Join us on Saturday, 25th January 2025, for an epic terrace house party under the stars. Expect good vibes, great music, and amazing company! Whether you're here to groove, chill, or just soak in the lively atmosphere, this night is all about creating memories that last.",
    location: "Hingna, Nagpur ",
    organizer: "The Local Talkies",
  },
  2: {
    id: 2,
    title: "Startup Summit ",
    date: "December 22, 2024",
    image: img19,
    time: "11:00 AM - 1:00 PM",
    description:
      "An inspiring event bringing together entrepreneurs, industry leaders, and innovators to explore the future of startups. Gain valuable insights on scaling businesses, funding strategies, and emerging market trends through expert-led sessions and panel discussions. Network with investors, mentors, and like-minded peers to collaborate and grow. Whether you’re a budding entrepreneur or a seasoned founder, this summit is your gateway to unleashing new opportunities and driving success!",
    location: "Chhatrapati Square, Nagpur ",
    organizer: "Akash Ghodmare",
  },
  3: {
    id: 3,
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    image: img40,
    time: "7:00 AM - 8:00 AM",
    description:
      "Discover inner peace and rejuvenation through a holistic wellness experience. Engage in soothing Asanas, revitalizing Pranayama, deep Self-Connection, and empowering Journaling and Affirmation Practice.\n\n " +
      "About the organiser - Utsaahi is a certified yoga practitioner with three years of experience, dedicated to helping individuals improve their health and connect with their inner selves. Her journey with yoga began as a means of self-healing and weight loss while exploring her inner potential. During that period, she faced challenges with an unsettled mind and self-criticism.\n\n" +
      " Through yoga, Utsaahi transformed her life, found balance, and embraced self-compassion. Today, she is committed to guiding others on their path to physical, mental, and spiritual well-being.",
    location: "Japanese Garden, Nagpur",
    organizer: "Utsaahi",
  },
};

function EventDetails() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Find the event by slug
  const event = Object.values(eventDetails).find(
    (e) => e.title.replaceAll(" ", "-").toLowerCase() === slug
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        setIsLoggedIn(true);
        setCurrentUser(user);
      } else {
        setIsLoggedIn(false);
        setCurrentUser(null);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  if (!event) {
    return (
      <div className="text-center">
        <p>Event not found!</p>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    );
  }

  const shareUrl = window.location.href;
  const shareTitle = `${event.title} - Check out this amazing event!`;

  const handleBookNow = () => {
    if (!isLoggedIn) {
      // Store the current event details for redirection after login
      sessionStorage.setItem("intendedEventId", event.id);
      sessionStorage.setItem("intendedEventSlug", slug);

      // Redirect to login page
      navigate("/customer-login");
    } else {
      // If logged in, proceed to checkout
      navigate("/checkout", {
        state: {
          eventId: event.id,
          eventTitle: event.title,
        },
      });
    }
  };

  return (
    <div className="bg-white">
      <header className="bg-gray-800 text-white p-4">
        <h1 className="text-2xl font-bold">{event.title}</h1>
        <p>Home / Event Details</p>
      </header>
      <main className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <img
              src={event.image}
              alt={event.title}
              className="w-full rounded-lg mb-4"
            />
            <h2 className="text-xl font-bold mb-2">Description</h2>
            {/* Render each paragraph separately */}
            {event.description.split("\n\n").map((para, index) => (
              <p key={index} className="mb-4">
                {para.trim()}
              </p>
            ))}
            <h2 className="text-xl font-bold mt-4 mb-2">Location</h2>
            <p>{event.location}</p>
            <h2 className="text-xl font-bold mt-4 mb-2">Date</h2>
            <p>{event.date}</p>
          </div>
          <div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h2 className="text-xl font-bold mb-4">Event Details</h2>
              <p>Organizer: {event.organizer}</p>
              <p>Date: {event.date}</p>
              <p>Time: {event.time}</p>
            </div>
            <button
              className="w-full bg-teal-500 text-white py-2 rounded-lg mt-4 hover:bg-teal-600 transition duration-300"
              onClick={handleBookNow}
            >
              {isLoggedIn ? "Book Now" : "Login to Book"}
            </button>
            {event.id === 3 && (
              <p className="text-sm text-gray-600 mt-2 text-center italic">
                * The payment for this event will be done at the venue
              </p>
            )}
          </div>
        </div>
      </main>
      {/* Share Button */}
      <div className="fixed bottom-4 right-4 flex flex-col items-center gap-2">
        <WhatsappShareButton url={shareUrl} title={shareTitle}>
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <FacebookShareButton url={shareUrl} quote={shareTitle}>
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
      </div>
    </div>
  );
}

export default EventDetails;
