import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  loadRazorpayScript,
  createRazorpayOrder,
} from "../../services/razorpay";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

// Event details configuration
const eventDetails = {
  1: {
    title: "Terrace House Party",
    date: "January 25, 2025",
    time: "8:00 PM - 12:00 AM",
    price: 450, // Original paid event price
    testPrice: 1, // Test price set to 1 rupee
    location: "Hingna, Nagpur ",
  },
  2: {
    title: "Startup Summit",
    date: "December 22, 2024",
    time: "11:00 AM - 1:00 PM",
    price: 99, // Original paid event price
    testPrice: 1, // Test price set to 1 rupee
    location: "Chhatrapati Square, Nagpur ",
  },
  3: {
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    time: "7:00 AM - 8:00 AM",
    price: 0, // Free event
    testPrice: 1, // Test price set to 1 rupee
    location: "Japanese Garden, Nagpur",
  },
};

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.state?.eventId || "1";

  // NEW: Add state for testing mode
  // const [isTestMode, setIsTestMode] = useState(false);

  // Dynamically select price based on test mode
  const event = {
    ...eventDetails[eventId],
    price: eventDetails[eventId].price,
    // price: isTestMode
    //   ? eventDetails[eventId].testPrice
    //   : eventDetails[eventId].price,
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });

  // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        // Pre-fill email if available
        setBillingDetails((prev) => ({
          ...prev,
          email: user.email || prev.email,
        }));
      } else {
        setCurrentUser(null);
        // Redirect to login if not authenticated
        navigate("/login", {
          state: {
            from: location.pathname,
            eventId: eventId,
          },
        });
      }
    });

    return () => unsubscribe();
  }, [navigate, location, eventId]);

  // Validate form inputs
  const validateForm = () => {
    // Comprehensive validation
    const validations = [
      {
        condition: billingDetails.firstName.trim() === "",
        message: "First name is required",
      },
      {
        condition: billingDetails.lastName.trim() === "",
        message: "Last name is required",
      },
      {
        condition: !billingDetails.email.includes("@"),
        message: "Please enter a valid email",
      },
      {
        condition: !billingDetails.phone.match(/^\+?[\d\s-]{10,}$/),
        message: "Please enter a valid phone number",
      },
      {
        condition: billingDetails.city.trim() === "",
        message: "City is required",
      },
      {
        condition: billingDetails.address.trim() === "",
        message: "Address is required",
      },
    ];

    const failedValidation = validations.find((v) => v.condition);

    if (failedValidation) {
      setError(failedValidation.message);
      return false;
    }

    return true;
  };

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prev) => ({ ...prev, [name]: value }));
    setError(null);
  };

  // Razorpay payment initialization
  const initializeRazorpayPayment = async (orderId, docRef) => {
    if (!window.Razorpay) {
      throw new Error("Razorpay SDK failed to load");
    }

    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: event.price * 100, // Convert to paisa
      currency: "INR",
      name: "Event Tickets",
      description: `Ticket for ${event.title}`,
      order_id: orderId,
      handler: async function (response) {
        try {
          const apiUrl = "https://connecticx-server.vercel.app";
          // process.env.NODE_ENV === "prod"
          //   ? "https://connecticx-server.vercel.app/"
          //   : "http://localhost:3001";

          // const verificationResponse = await fetch(
          //   `${apiUrl}/api/verify-payment`,
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({
          //       razorpay_payment_id: response.razorpay_payment_id,
          //       razorpay_order_id: response.razorpay_order_id,
          //       razorpay_signature: response.razorpay_signature,
          //     }),
          //   }
          // );

          // Add more robust error handling
          // if (!verificationResponse.ok) {
          //   // Try to parse error response, fallback to text
          //   let errorBody;
          //   try {
          //     errorBody = await verificationResponse.json();
          //   } catch {
          //     errorBody = await verificationResponse.text();
          //   }

          //   console.error("Verification Response Error:", {
          //     status: verificationResponse.status,
          //     body: errorBody,
          //   });

          //   throw new Error(
          //     `Payment verification failed: ${verificationResponse.status}`
          //   );
          // }

          // const responseBody = await verificationResponse.json();

          // if (responseBody.success) {
          // Create a verified payment record
          const verifiedPaymentRef = await addDoc(
            collection(db, "verifiedPayments"),
            {
              orderId: docRef.id,
              eventId: eventId,
              eventTitle: event.title,
              customerId: currentUser.uid,
              customerName: `${billingDetails.firstName} ${billingDetails.lastName}`,
              customerEmail: billingDetails.email,
              paymentStatus: "success",
              completedAt: new Date().toISOString(),
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              // paymentDetails: responseBody.paymentDetails || {},
              billingDetails: billingDetails,
            }
          );

          // Update the original order document
          await updateDoc(doc(db, "orders", docRef.id), {
            paymentStatus: "success",
            verifiedPaymentId: verifiedPaymentRef.id,
          });

          navigate(`/confirmation/${docRef.id}`);
          // } else {
          //   throw new Error(
          //     responseBody.message || "Payment verification failed"
          //   );
          // }
        } catch (error) {
          console.error("Complete Verification Error Details:", {
            name: error.name,
            message: error.message,
            stack: error.stack,
          });

          // Create a failed payment record
          await addDoc(collection(db, "verifiedPayments"), {
            orderId: docRef.id,
            eventId: eventId,
            eventTitle: event.title,
            customerName: `${billingDetails.firstName} ${billingDetails.lastName}`,
            customerEmail: billingDetails.email,
            paymentStatus: "failed",
            errorMessage: error.message,
            createdAt: new Date().toISOString(),
          });

          // Update the original order document
          await updateDoc(doc(db, "orders", docRef.id), {
            paymentStatus: "failed",
            paymentErrorMessage: error.message,
          });

          setError(error.message || "Payment failed. Please try again.");
          setLoading(false);
        }
      },
      prefill: {
        name: `${billingDetails.firstName} ${billingDetails.lastName}`,
        email: billingDetails.email,
        contact: billingDetails.phone,
      },
      theme: {
        color: "#0D9488",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
        },
      },
    };

    const razorpayInstance = new window.Razorpay(options);
    razorpayInstance.open();
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      return;
    }

    // Ensure user is authenticated
    if (!currentUser) {
      setError("Please log in to complete your registration");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Create order in Firestore
      const orderData = {
        userId: currentUser.uid,
        ...billingDetails,
        eventId,
        eventTitle: event.title,
        eventDate: event.date,
        eventLocation: event.location,
        ticketPrice: event.price,
        paymentStatus: event.price === 0 ? "confirmed" : "pending",
        createdAt: new Date().toISOString(),
      };

      const docRef = await addDoc(collection(db, "orders"), orderData);

      // Handle free vs paid events
      if (event.price === 0) {
        // Free event - directly navigate to confirmation
        navigate(`/confirmation/${docRef.id}`);
      } else {
        // Paid event - proceed with Razorpay
        // Load Razorpay script
        const isLoaded = await loadRazorpayScript();
        if (!isLoaded) {
          throw new Error(
            "Payment system failed to load. Please try again later."
          );
        }

        // Create Razorpay order
        const orderData = {
          amount: event.price * 100, // Convert to paisa
          currency: "INR",
          receipt: docRef.id,
        };

        const razorpayOrder = await createRazorpayOrder(orderData);

        if (!razorpayOrder.success) {
          throw new Error(razorpayOrder.message || "Failed to create order");
        }

        // Initialize Razorpay payment
        await initializeRazorpayPayment(razorpayOrder.order.id, docRef);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      setError(
        error.message || "An unexpected error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <header className="bg-gray-800 text-white p-4">
        <h1 className="text-2xl font-bold">Checkout</h1>
        <p>Home / Checkout</p>
        {/* NEW: Test Mode Toggle */}
        {/* <div className="flex items-center mt-2">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isTestMode}
              onChange={() => setIsTestMode(!isTestMode)}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Test Mode (1 ₹ Ticket)
            </span>
          </label>
        </div> */}
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <h2 className="text-2xl font-bold mb-4">Ticket Registration</h2>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-2">First Name *</label>
                  <input
                    type="text"
                    name="firstName"
                    value={billingDetails.firstName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Last Name *</label>
                  <input
                    type="text"
                    name="lastName"
                    value={billingDetails.lastName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-2">Email *</label>
                  <input
                    type="email"
                    name="email"
                    value={billingDetails.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Phone *</label>
                  <input
                    type="tel"
                    name="phone"
                    value={billingDetails.phone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-2">City *</label>
                  <input
                    type="text"
                    name="city"
                    value={billingDetails.city}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Zip/Post Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={billingDetails.zipCode}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>

              <div className="mt-4">
                <label className="block mb-2">Address *</label>
                <textarea
                  name="address"
                  value={billingDetails.address}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                  required
                ></textarea>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-teal-500 text-white py-2 rounded-lg w-full disabled:opacity-50"
                >
                  {loading
                    ? "Processing..."
                    : event.price === 0
                    ? "Proceed to Ticket"
                    : "Proceed to Payment"}
                </button>
              </div>
            </form>
          </div>

          {/* Order Summary */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Ticket Details</h2>
            <div className="bg-white p-4 rounded-lg mb-4">
              <h3 className="font-bold">{event.title}</h3>
              <p className="text-sm text-gray-600">
                {event.date} @ {event.time}
              </p>
              <p className="text-sm text-gray-600">{event.location}</p>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Standard Ticket</span>
                <span>1x</span>
              </div>
              <div className="flex justify-between">
                <span>Total Tickets</span>
                <span>1</span>
              </div>
              <div className="flex justify-between">
                <span>Ticket Price</span>
                <span>{`₹${event.price}`}</span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total</span>
                <span>{`₹${event.price}`}</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
