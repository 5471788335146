// src/services/razorpay.js
export const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const createRazorpayOrder = async (orderData) => {
  try {
    const apiUrl = "https://connecticx-server.vercel.app/api/create-order";
    // process.env.NODE_ENV === "prod"
    //   ? "https://connecticx-server.vercel.app/api/create-order"
    //   : "http://localhost:3001/api/create-order"; // Use relative path

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
      credentials: "same-origin",
    });
    console.log(response.body);
    if (!response.ok) {
      const errorBody = await response.text();
      console.error("Order creation failed:", {
        status: response.status,
        body: errorBody,
      });
      throw new Error(`Order creation failed: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Comprehensive Razorpay order creation error:", error);
    throw error;
  }
};
