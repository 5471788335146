import React from "react";
import { useNavigate } from "react-router-dom";
import img19 from "../../images/19.png";
import img20 from "../../images/20.png";
import img40 from "../../images/yoga.jpg";

const events = [
  {
    id: 1,
    title: "Terrace House Party",
    date: "January 25, 2025",
    time: "8:00 PM - 12:00 AM",
    location: "Hingna, Nagpur ",
    description:
      "Join us on Saturday, 25th January 2025, for a vibrant terrace house party under the stars! Enjoy great music, good vibes, and amazing company for a night of unforgettable memories. Don’t miss out on the fun—mark your calendars now!",
    image: img20,
    price: 450,
    organizer: "The Local Talkies",
  },
  {
    id: 2,
    title: "Startup Summit ",
    date: "December 22, 2024",
    time: "11:00 AM - 1:00 PM",
    location: "Chhatrapati Square, Nagpur ",
    description:
      " An inspiring event bringing together entrepreneurs, industry leaders, and innovators to explore the future of startups. Gain valuable insights on scaling businesses, funding strategies, and emerging market trends through expert-led sessions and panel discussions.",
    image: img19,
    price: 99,
    organizer: "Akash Ghodmare",
  },
  {
    id: 3,
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    time: "7:00 AM - 8:00 AM",
    location: "Japanese Garden, Nagpur",
    description:
      "Discover inner peace and rejuvenation through a holistic wellness experience. Engage in soothing Asanas, revitalizing Pranayama, deep Self-Connection, and empowering Journaling and Affirmation Practice. Join us to ",
    image: img40,
    price: "Free",
    organizer: "Utsaahi",
  },
];

function EventCard({ event }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const eventSlug = event.title.replaceAll(" ", "-").toLowerCase();
    navigate(`/event/${eventSlug}`);
  };

  // Function to abbreviate month
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    return `${month} ${date.getDate()}`;
  };

  const renderPrice = () => {
    if (event.id === 3) {
      return "Free"; // Special case for event ID 1
    }
    return event.price === 0 ? "Free" : `₹${event.price}`;
  };

  return (
    <div
      className="bg-purple-700 rounded-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer overflow-hidden text-white"
      onClick={handleClick}
    >
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h3 className="font-bold text-lg mb-2">{event.title}</h3>
        <p className="text-sm text-purple-300 mb-4">{event.description}</p>
        <div className="flex justify-between items-center text-purple-100 font-bold">
          <div>{renderPrice()}</div>
          <div>{formatDate(event.date)}</div>
        </div>
      </div>
    </div>
  );
}

function EventList() {
  return (
    <div className="h-auto py-10 mt-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default EventList;
