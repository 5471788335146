import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus("");

    try {
      // Initialize emailjs
      emailjs.init("GgBhreKIFK1txNDet");

      // First try to send the email
      const templateParams = {
        from_name: name,
        from_email: email,
        subject: subject,
        message: message,
        to_email: "md.nawaidsheikh@gmail.com",
      };

      await emailjs.send("service_9vcnvss", "template_vnv7vye", templateParams);

      // If email sends successfully, then add to Firestore
      const ticketRef = collection(db, "supportTickets");
      const ticketData = {
        email,
        name,
        subject,
        message,
        status: "Open",
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      };

      await addDoc(ticketRef, ticketData);

      setSubmitStatus("Message sent successfully!");
      // Clear form
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
      if (error.message.includes("permissions")) {
        setSubmitStatus("System error. Please try again later.");
      } else {
        setSubmitStatus("Failed to send message. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white font-sans">
      {/* Header Section */}
      <header className="text-black text-center py-16">
        <h1 className="text-4xl font-bold">Contact</h1>
        <p className="mt-2">Home / Contact</p>
      </header>

      {/* Contact Information and Form */}
      <div className="container mx-auto py-12 px-4 flex flex-col md:flex-row gap-8">
        {/* Contact Information */}
        <div className="bg-gray-100 rounded-lg p-8 flex flex-col gap-6 w-full md:w-1/2">
          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#128205;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Address</h3>
              <p>India, Maharashtra</p>
              <p>Nagpur, Shivaji Nagar</p>
              <p>440010</p>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#9993;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Email</h3>
              <p>connecticx7@gmail.com</p>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#128222;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Phone</h3>
              <p>(225)571-6212</p>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="w-full md:w-1/2 bg-white p-8 shadow-md rounded-lg">
          <h3 className="text-2xl font-semibold mb-6">Send A Message</h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col md:flex-row gap-4">
              <input
                type="text"
                placeholder="Enter Your Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="border p-2 w-full rounded-md"
              />
              <input
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="border p-2 w-full rounded-md"
              />
            </div>
            <input
              type="text"
              placeholder="Enter Email Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className="border p-2 w-full rounded-md"
            />
            <textarea
              placeholder="Write Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="border p-2 w-full rounded-md h-32 resize-none"
            ></textarea>
            {submitStatus && (
              <div
                className={`text-center p-2 rounded ${
                  submitStatus.includes("successfully")
                    ? "bg-green-50 text-green-600"
                    : "bg-red-50 text-red-600"
                }`}
              >
                {submitStatus}
              </div>
            )}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-purple-500 text-white px-6 py-2 rounded-md w-full transition-all
                ${
                  isSubmitting
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-purple-600 active:bg-purple-700"
                }`}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
